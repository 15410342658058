import { Strong } from '@smart-kasa/ui';

import type { IShop } from 'services/api/core/shop/types';
import styles from './Report.module.scss';

interface IReportShop {
  shop: IShop;
}

export const ReportShop = ({ shop }: IReportShop) => (
  <div className={styles.shop}>
    <Strong>{shop.taxpayerName}</Strong>

    <p>{shop.title}</p>
    <p>{shop.address && shop.address.content}</p>
  </div>
);

import { FC } from 'react';
import { UAH } from '@smart-kasa/ui';

import type { IReportPaymentMethod } from 'services/api/core/reports/types';

import { ReportRow } from './ReportRow';

interface IPaymentMethod {
  paymentMethod: IReportPaymentMethod;
}

export const ReportPaymentMethod: FC<IPaymentMethod> = ({ paymentMethod }) => (
  <ReportRow
    title={`- ${paymentMethod.name}`}
    value={<UAH value={paymentMethod.total} />}
    appearance="none"
  />
);

import { Fragment, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  DataTable,
  IDataTableColumn,
  Tag,
  Icon,
  Button,
  InlineSpace,
  Inline,
  Hint,
  Row,
  Input,
  Paragraph,
  Page,
  AvatarItem,
  Strong,
} from '@smart-kasa/ui';

import { ButtonIconLink, BlankView, Picture } from 'components';
import { useAppSelector } from 'hooks/useAppStore';
import { useGetShopsQuery } from 'services/api/core/shop/api';
import type { IShop } from 'services/api/core/shop/types';
import { ShopState } from 'services/api/core/shop/enums';

import { filterShops } from 'features/shops/slice';
import styles from './ShopList.module.scss';

export const ShopList = () => {
  const navigate = useNavigate();
  const [term, setTerm] = useState<string>('');
  const { isLoading } = useGetShopsQuery();
  const shops = useAppSelector((state) => filterShops(state, term));

  const columns: IDataTableColumn<IShop>[] = [
    {
      key: 'id',
      title: 'ID',
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'title',
      title: 'Назва торгової точки',
      render: (title, { taxpayerName, picture, address }) => (
        <AvatarItem
          avatar={<Picture icon="business" src={picture} />}
          primaryText={
            <Fragment>
              {taxpayerName && (
                <Inline>
                  <Icon name="tax" size="small" /> {taxpayerName}
                </Inline>
              )}
              <Strong>{title}</Strong>
            </Fragment>
          }
          secondaryText={address?.content}
        />
      ),
    },
    {
      key: 'taxpayerNumber',
      title: 'ПН/ІД',
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state: number) => (
        <Tag color={state ? 'green' : 'orange'}>{state ? 'Деактивовано' : 'Активна'}</Tag>
      ),
    },
    {
      key: 'terminalsCount',
      title: 'Відомості',
      render: (terminalsCount, { employeesCount }) => (
        <Fragment>
          <Hint margin={0} className={styles.counter}>
            <Icon name="smartKasa" size="x-medium" />
            <InlineSpace size={5} />
            Обладнання: {terminalsCount}
          </Hint>
          <Hint margin={0} className={styles.counter}>
            <Icon name="members" size="x-medium" />
            <InlineSpace size={5} />
            Працівники: {employeesCount}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'id',
      render: (id: number, { state }) => (
        <Inline>
          <ButtonIconLink to={`/shops/${id}`} onClick={(event) => event.stopPropagation()}>
            <Icon size="large" name="eye" />
          </ButtonIconLink>
          <InlineSpace />
          <ButtonIconLink to={`/shops/${id}/edit`} onClick={(event) => event.stopPropagation()}>
            <Icon size="large" name="edit" />
          </ButtonIconLink>
          <InlineSpace />
          {state === ShopState.deleted && (
            <ButtonIconLink to={`/shops/${id}/delete`} onClick={(event) => event.stopPropagation()}>
              <Icon size="large" name="trash" />
            </ButtonIconLink>
          )}
        </Inline>
      ),
    },
  ];

  return (
    <Page
      title="Торгові точки"
      actions={
        <Row>
          <Input onChange={(event) => setTerm(event.target.value)} placeholder="Пошук" />
          <InlineSpace />
          <Button
            color="accent"
            rightIcon={<Icon name="add" size="small" />}
            onClick={() => navigate('/shops/create')}
          >
            Додати
          </Button>
        </Row>
      }
    >
      <DataTable
        data={shops}
        columns={columns}
        rowIdKey="id"
        isLoading={isLoading}
        onRowClick={(shop) => navigate(`/shops/${shop.id}`)}
        emptyText={
          <BlankView>
            Прив'яжіть Смарт Касу до торгової точки
            <Paragraph>
              Це - Ваш віддалений офіс. Тут буде структуроване все Ваше обладнання, працівники та
              ФОП у розрізі торгових точкок для зручності налаштувань і контролю. Додайте торгову
              точку та розпочніть автоматизацію Вашого бізнесу.
            </Paragraph>
          </BlankView>
        }
      />

      <Outlet />
    </Page>
  );
};

import dayjs from 'dayjs';

const TIMEZONE_OFFSET = 60_000;
const DATE_FORMAT = 'YYYY-MM-DD';

export const dateToISO8601String = (date: Date): string => {
  return new Date(date.getTime() - date.getTimezoneOffset() * TIMEZONE_OFFSET).toISOString();
};

export const formatDate = (date: Date): string => {
  const isoDate = dateToISO8601String(date);

  return dayjs(isoDate).format(DATE_FORMAT);
};

import { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';
import { Page, Button, Icon, InlineSpace, Pagination } from '@smart-kasa/ui';

import { useQueryPagination, useQuery } from 'hooks';
import useModal from 'hooks/useModal';
import { formatDate } from 'utils/date';
import { useGetReceiptsQuery } from 'services/api/core/receipts/api';
import { useLazyExportReceiptsQuery } from 'services/api/core/export/api';
import { ReceiptTable } from 'features/receipts/components/ReceiptTable';
import { ReceiptFilter } from 'features/receipts/components/ReceiptFilter';

export const ReceiptList = () => {
  const { onPageChange, onSortChange } = useQueryPagination();
  const { query, push: setQuery } = useQuery();
  const { isShowing, showModal, hideModal } = useModal();
  const params = useMemo(
    () => ({
      sortMode: 'desc',
      sortColumn: 'created_at',
      dateStart: formatDate(dayjs().startOf('month').toDate()),
      dateEnd: formatDate(dayjs().toDate()),
      ...query,
    }),
    [query]
  );
  const { data: receipts, isFetching } = useGetReceiptsQuery(params);
  const [download] = useLazyExportReceiptsQuery();

  return (
    <Page
      title="Чеки"
      actions={
        <Fragment>
          <Button rightIcon={<Icon name="template" />} onClick={() => download(params)}>
            Експорт
          </Button>
          <InlineSpace />
          <Button color="primary" rightIcon={<Icon name="filter" />} onClick={showModal}>
            Фільтр
          </Button>
        </Fragment>
      }
    >
      <ReceiptTable
        data={receipts?.data}
        isLoading={isFetching}
        sortMode={params.sortMode}
        sortColumn={params.sortColumn}
        onSortChange={onSortChange}
      />
      <Pagination
        totalItems={receipts?.meta?.totalCount}
        currentPage={receipts?.meta?.currentPage}
        perPage={receipts?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <ReceiptFilter
        show={isShowing}
        onClose={hideModal}
        initialValues={params}
        onSubmit={setQuery}
      />
    </Page>
  );
};

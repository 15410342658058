import { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';
import { PageHeader } from '@smart-kasa/ui';

import { useQuery } from 'hooks';
import { formatDate } from 'utils/date';
import type { PaymentTransactionListQuery } from 'services/api/core/transactions/types';
import {
  useGetTransactionSalesQuery,
  useGetTransactionsQuery,
} from 'services/api/core/transactions/api';

import { SalesFilter } from 'features/stats/components/SalesFilter';
import { IStatsCardsRow, StatsCards } from 'features/stats/components/StatsCards';
import { SalesChart } from 'features/stats/components/SalesChart';
import { SalesTransactions } from 'features/stats/components/SalesTransactions';

export const StatsSales = () => {
  const { query, push: setQuery } = useQuery();
  const params: PaymentTransactionListQuery = useMemo(
    () => ({
      dateStart: formatDate(dayjs().startOf('month').toDate()),
      dateEnd: formatDate(dayjs().toDate()),
      ...query,
    }),
    [query]
  );

  const { data: sales } = useGetTransactionSalesQuery(params);
  const { data: transactions } = useGetTransactionsQuery(params);
  const stats: IStatsCardsRow[] = useMemo(
    () => [
      {
        title: 'Готівка',
        value: sales?.data.cashAmount,
        money: true,
      },
      {
        title: 'Безготівкова',
        value: sales?.data.cashlessAmount,
        money: true,
      },
      {
        title: 'Загальний обіг',
        value: sales?.data.totalAmount,
        money: true,
      },
    ],
    [sales]
  );

  return (
    <Fragment>
      <PageHeader>Статистика</PageHeader>
      <SalesFilter initialValues={params} onChange={setQuery} />

      {stats && <StatsCards data={stats} />}
      {sales && <SalesChart data={sales?.data} />}
      {transactions && <SalesTransactions transactions={transactions?.data} />}
    </Fragment>
  );
};

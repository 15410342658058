export enum TAX_GROUP {
  GENERAL = 0,
  PDV_20 = 1,
  PDV_7 = 2,
  PDV_0 = 3,
  BEZ_PDV = 4,
  AKTSYZ_5 = 5,
  AKTSYZ_5_PDV_20 = 6,
  AKTSYZ_5_PDV_0 = 7,
  AKTSYZ_0 = 8,
  AKTSYZ_0_PDV_20 = 9,
  AKTSYZ_0_PDV_0 = 10,
}

export enum TAX_LETTER {
  GENERAL = '',
  PDV_20 = 'А',
  PDV_7 = 'Б',
  PDV_0 = 'В',
  BEZ_PDV = 'Д',
  AKTSYZ_5 = 'З',
  AKTSYZ_5_PDV_20 = 'АЗ',
  AKTSYZ_5_PDV_0 = 'ВЗ',
  AKTSYZ_0 = 'Г',
  AKTSYZ_0_PDV_20 = 'ГА',
  AKTSYZ_0_PDV_0 = 'ГВ',
}

export const TAX_LETTER_MAPPING: Record<TAX_LETTER, string> = {
  [TAX_LETTER.GENERAL]: 'Не обрано',
  [TAX_LETTER.PDV_20]: 'ПДВ (А) 20%',
  [TAX_LETTER.PDV_7]: 'ПДВ (Б) 7%',
  [TAX_LETTER.PDV_0]: 'ПДВ (В) 0%',
  [TAX_LETTER.BEZ_PDV]: 'Без ПДВ (Д)',
  [TAX_LETTER.AKTSYZ_5]: 'Акциз (З) 5%',
  [TAX_LETTER.AKTSYZ_5_PDV_20]: 'Акциз (З) 5% + ПДВ (А) 20%',
  [TAX_LETTER.AKTSYZ_5_PDV_0]: 'Акциз (З) 5% + ПДВ (В) 0%',
  [TAX_LETTER.AKTSYZ_0]: 'Акциз (Г) 0%',
  [TAX_LETTER.AKTSYZ_0_PDV_20]: 'Акциз (Г) 0% + ПДВ (А) 20%',
  [TAX_LETTER.AKTSYZ_0_PDV_0]: 'Акциз (Г) 0% + ПДВ (В) 0%',
};

interface ITaxGroupMapping {
  value: number;
  letter: TAX_LETTER;
  label: string;
}

export const TAX_GROUP_MAPPING: Record<TAX_GROUP, ITaxGroupMapping> = {
  [TAX_GROUP.GENERAL]: {
    value: TAX_GROUP.GENERAL,
    letter: TAX_LETTER.GENERAL,
    label: TAX_LETTER_MAPPING[TAX_LETTER.GENERAL],
  },
  [TAX_GROUP.PDV_20]: {
    value: TAX_GROUP.PDV_20,
    letter: TAX_LETTER.PDV_20,
    label: TAX_LETTER_MAPPING[TAX_LETTER.PDV_20],
  },
  [TAX_GROUP.PDV_7]: {
    value: TAX_GROUP.PDV_7,
    letter: TAX_LETTER.PDV_7,
    label: TAX_LETTER_MAPPING[TAX_LETTER.PDV_7],
  },
  [TAX_GROUP.PDV_0]: {
    value: TAX_GROUP.PDV_0,
    letter: TAX_LETTER.PDV_0,
    label: TAX_LETTER_MAPPING[TAX_LETTER.PDV_0],
  },
  [TAX_GROUP.BEZ_PDV]: {
    value: TAX_GROUP.BEZ_PDV,
    letter: TAX_LETTER.BEZ_PDV,
    label: TAX_LETTER_MAPPING[TAX_LETTER.BEZ_PDV],
  },
  [TAX_GROUP.AKTSYZ_5]: {
    value: TAX_GROUP.AKTSYZ_5,
    letter: TAX_LETTER.AKTSYZ_5,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_5],
  },
  [TAX_GROUP.AKTSYZ_5_PDV_20]: {
    value: TAX_GROUP.AKTSYZ_5_PDV_20,
    letter: TAX_LETTER.AKTSYZ_5_PDV_20,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_5_PDV_20],
  },
  [TAX_GROUP.AKTSYZ_5_PDV_0]: {
    value: TAX_GROUP.AKTSYZ_5_PDV_0,
    letter: TAX_LETTER.AKTSYZ_5_PDV_0,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_5_PDV_0],
  },
  [TAX_GROUP.AKTSYZ_0]: {
    value: TAX_GROUP.AKTSYZ_0,
    letter: TAX_LETTER.AKTSYZ_0,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_0],
  },
  [TAX_GROUP.AKTSYZ_0_PDV_20]: {
    value: TAX_GROUP.AKTSYZ_0_PDV_20,
    letter: TAX_LETTER.AKTSYZ_0_PDV_20,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_0_PDV_20],
  },
  [TAX_GROUP.AKTSYZ_0_PDV_0]: {
    value: TAX_GROUP.AKTSYZ_0_PDV_0,
    letter: TAX_LETTER.AKTSYZ_0_PDV_0,
    label: TAX_LETTER_MAPPING[TAX_LETTER.AKTSYZ_0_PDV_0],
  },
};

export const taxGroupOptions = Object.values(TAX_GROUP_MAPPING);

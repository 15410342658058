import { FC, Fragment } from 'react';
import { UAH } from '@smart-kasa/ui';

import type { IReportTotals } from 'services/api/core/reports/types';

import { ReportTax } from './ReportTax';
import { ReportPaymentMethod } from './ReportPaymentMethod';
import { ReportGroup } from './ReportGroup';
import { ReportRow } from './ReportRow';
import { ReportSubtitle } from './ReportSubtitle';

interface ITax {
  title?: string;
  totals: IReportTotals;
}

export const ReportTotals: FC<ITax> = ({ title, totals }) => {
  return (
    <ReportGroup title={title || 'Підсумки'}>
      <ReportRow title="Загальна сума:" value={<UAH value={totals.total} />} />
      <ReportRow title="Загальна сума комісії:" value={<UAH value={totals.commission} />} />
      <ReportRow title="Кількість чеків:" value={totals.receiptsCount} />
      <ReportRow title="Кількість операції переказу коштів:" value={totals.transactionsCount} />

      {totals.paymentMethods && (
        <Fragment>
          <ReportSubtitle>Підсумки по засобам оплати:</ReportSubtitle>

          {totals.paymentMethods.map((paymentMethod: any, index: number) => (
            <ReportPaymentMethod paymentMethod={paymentMethod} key={index} />
          ))}
        </Fragment>
      )}

      {totals.taxes && (
        <Fragment>
          <ReportSubtitle>Підсумки по податкам/зборам:</ReportSubtitle>

          {totals.taxes.map((tax: any, index: number) => (
            <ReportTax tax={tax} key={index} />
          ))}
        </Fragment>
      )}
    </ReportGroup>
  );
};

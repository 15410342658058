import { Route, Routes } from 'react-router-dom';

import { NoMatch } from 'components';

import { TransactionList } from './routes/TransactionList';

export const Transactions = () => (
  <Routes>
    <Route index element={<TransactionList />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

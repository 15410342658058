import { Fragment, useMemo } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  DataTable,
  IDataTableColumn,
  Pagination,
  Card,
  Hint,
  Strong,
  Paragraph,
  Tag,
  UAH,
  Button,
  Icon,
} from '@smart-kasa/ui';

import { useQueryPagination, useQuery } from 'hooks';
import { useGetTaxesReportQuery } from 'services/api/core/reports/api';
import { useLazyExportReportsQuery } from 'services/api/core/export/api';
import type { ITaxesReport } from 'services/api/core/reports/types';

import { ReportLayout } from 'features/reports/components/ReportLayout';
import { ReportTaxesFilter } from 'features/reports/components/ReportTaxesFilter';

export const ReportTaxes = () => {
  const navigate = useNavigate();
  const { query, push: setQuery } = useQuery();
  const params = useMemo(
    () => ({
      sortMode: 'desc',
      sortColumn: 'created_at',
      ...query,
    }),
    [query]
  );
  const { data: reports, isLoading } = useGetTaxesReportQuery(params);
  const { onPageChange, onSortChange } = useQueryPagination();
  const [download] = useLazyExportReportsQuery();

  const columns: IDataTableColumn<ITaxesReport>[] = [
    {
      key: 'createdAt',
      sort: true,
      title: 'Дата',
      render: (createdAt) => (
        <Hint>
          <Strong>
            <Paragraph>
              <FormattedDate value={createdAt} dateStyle="short" />
            </Paragraph>
            <Paragraph>
              <FormattedDate value={createdAt} timeStyle="short" />
            </Paragraph>
          </Strong>
        </Hint>
      ),
    },
    {
      key: 'fiscalNumber',
      title: 'Тип, ФН/пРРО',
      render: (_, { payload: { fiscalNumber, registrarFiscalNumber } }) => (
        <Fragment>
          {fiscalNumber ? (
            <Fragment>
              <Tag color="violet">Фіскальний звіт</Tag>
              <Hint>
                {fiscalNumber} / {registrarFiscalNumber}
              </Hint>
            </Fragment>
          ) : (
            <Tag color="green">Торговий звіт</Tag>
          )}
        </Fragment>
      ),
    },
    {
      title: 'Касир',
      key: 'terminalUserName',
    },
    {
      key: 'shop',
      sort: 'shopId',
      title: 'Торгова точка',
      render: (shop) => shop?.title,
    },
    {
      key: 'terminalId',
      sort: true,
      title: 'Термінал №',
    },
    {
      key: 'turnoverAmount',
      title: 'Результат дня',
      render: (turnoverAmount) => <UAH value={turnoverAmount} />,
    },
    {
      key: 'cashAmount',
      title: 'Готівка в касі',
      render: (cashAmount) => <UAH value={cashAmount} />,
    },
    {
      key: 'serviceInput',
      title: 'Сл. внесення',
      render: (serviceInput) => <UAH value={serviceInput} />,
    },
    {
      key: 'serviceOutput',
      title: 'Сл. видача',
      render: (serviceOutput) => <UAH value={serviceOutput} />,
    },

    // Sale
    //
    {
      key: 'saleReceiptsCount',
      title: 'К-сть чеків продажу',
    },
    {
      key: 'saleTotal',
      title: 'Загальну сума продажу',
      render: (saleTotal) => <UAH value={saleTotal} />,
    },
    {
      key: 'saleTaxATotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>ПДВ (А) 20%</Paragraph>
        </Fragment>
      ),
      render: (saleTaxATotal) => <UAH value={saleTaxATotal} />,
    },
    {
      key: 'saleTaxBTotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>ПДВ (Б) 7%</Paragraph>
        </Fragment>
      ),
      render: (saleTaxBTotal) => <UAH value={saleTaxBTotal} />,
    },
    {
      key: 'saleTaxDTotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>Без ПДВ (Д)</Paragraph>
        </Fragment>
      ),
      render: (saleTaxDTotal) => <UAH value={saleTaxDTotal} />,
    },
    {
      key: 'saleTaxVTotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>ПДВ (В) 0%</Paragraph>
        </Fragment>
      ),
      render: (saleTaxVTotal) => <UAH value={saleTaxVTotal} />,
    },
    {
      key: 'saleTaxZTotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>Акциз (З) 5%</Paragraph>
        </Fragment>
      ),
      render: (saleTaxZTotal) => <UAH value={saleTaxZTotal} />,
    },
    {
      key: 'saleTaxGTotal',
      title: (
        <Fragment>
          <Paragraph>Податок продажу.</Paragraph>
          <Paragraph>Акциз (Г) 0%</Paragraph>
        </Fragment>
      ),
      render: (saleTaxGTotal) => <UAH value={saleTaxGTotal} />,
    },

    // Refund
    //
    {
      key: 'refundReceiptsCount',
      title: 'К-сть чеків повернення',
    },
    {
      key: 'refundTotal',
      title: 'Загальну сума повернення',
      render: (refundTotal) => <UAH value={refundTotal} />,
    },
    {
      key: 'refundTaxATotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>ПДВ (А) 20%</Paragraph>
        </Fragment>
      ),
      render: (refundTaxATotal) => <UAH value={refundTaxATotal} />,
    },
    {
      key: 'refundTaxBTotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>ПДВ (Б) 7%</Paragraph>
        </Fragment>
      ),
      render: (refundTaxBTotal) => <UAH value={refundTaxBTotal} />,
    },
    {
      key: 'refundTaxDTotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>Без ПДВ (Д)</Paragraph>
        </Fragment>
      ),
      render: (refundTaxDTotal) => <UAH value={refundTaxDTotal} />,
    },
    {
      key: 'refundTaxVTotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>ПДВ (В) 0%</Paragraph>
        </Fragment>
      ),
      render: (refundTaxVTotal) => <UAH value={refundTaxVTotal} />,
    },
    {
      key: 'refundTaxZTotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>Акциз (З) 5%</Paragraph>
        </Fragment>
      ),
      render: (refundTaxZTotal) => <UAH value={refundTaxZTotal} />,
    },
    {
      key: 'refundTaxGTotal',
      title: (
        <Fragment>
          <Paragraph>Податок повернення.</Paragraph>
          <Paragraph>Акциз (Г) 0%</Paragraph>
        </Fragment>
      ),
      render: (refundTaxGTotal) => <UAH value={refundTaxGTotal} />,
    },

    // Cash
    //
    {
      key: 'cashReceiptsCount',
      title: 'К-сть видаткових',
    },
    {
      key: 'cashTotal',
      title: 'Загальна сума видаткових чеків',
      render: (refundTotal) => <UAH value={refundTotal} />,
    },
  ];

  return (
    <ReportLayout
      actions={
        <Button rightIcon={<Icon name="template" />} onClick={() => download(query)}>
          Експорт
        </Button>
      }
      renderFilter={({ isVisible, onClose }) => (
        <ReportTaxesFilter
          initialValues={params}
          show={isVisible}
          onClose={onClose}
          onSubmit={setQuery}
        />
      )}
    >
      <Card>
        <DataTable
          rowIdKey="id"
          data={reports?.data}
          columns={columns}
          isLoading={isLoading}
          sortMode={params.sortMode}
          sortColumn={params.sortColumn}
          onRowClick={(report) => navigate(`/shifts/${report.shiftId}`)}
          onSortChange={onSortChange}
        />
        <Pagination
          totalItems={reports?.meta?.totalCount}
          currentPage={reports?.meta?.currentPage}
          perPage={reports?.meta?.limitValue}
          onPageChange={onPageChange}
        />
      </Card>
    </ReportLayout>
  );
};

import { Fragment } from 'react';
import { Hint, UAH } from '@smart-kasa/ui';

import type { IReportTax } from 'services/api/core/reports/types';

import { ReportRow } from './ReportRow';
import styles from './Report.module.scss';

interface ITax {
  tax: IReportTax;
}

export const ReportTax = ({ tax }: ITax) => (
  <ReportRow
    title={
      <Fragment>
        - {tax.name} ({tax.letter}){' '}
        <Hint margin={0} className={styles.hint}>
          / ставка {tax.percent} %
        </Hint>
      </Fragment>
    }
    value={<UAH value={tax.total} />}
  />
);

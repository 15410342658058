import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Drawer, FormElement, Label } from '@smart-kasa/ui';

import { Field, RangePicker } from 'components';
import { formatDate } from 'utils/date';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

export interface IShiftFilterForm {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values) => void;
}

export const ShiftFilterForm: FC<IShiftFilterForm> = ({
  initialValues,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();

  const handleSubmit = (fd) => {
    onSubmit(fd);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <Drawer
          onClose={onClose}
          title="Фільтр"
          footer={
            <Button onClick={handleSubmit} width="full" color="primary">
              Застосувати
            </Button>
          }
          {...rest}
        >
          <FormElement label={<Label>Переглянути за датами</Label>}>
            <RangePicker
              from={values.openedStart}
              to={values.openedEnd}
              onChangeDateStart={(date) => form.change('openedStart', formatDate(date))}
              onChangeDateEnd={(date) => form.change('openedEnd', formatDate(date))}
            />
          </FormElement>

          <Field.Input name="query" label="Пошук ФН пРРО" placeholder="Введіть текст для пошуку" />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
            isClearable
            value={Number(values.shopId)}
          />
          <TerminalSelectField
            name="terminalId"
            label="Термінал"
            placeholder="Оберіть термінал"
            options={terminals.filter((item) => item.shopId === Number(values.shopId))}
            isClearable
            value={Number(values.terminalId)}
          />
        </Drawer>
      )}
    </Form>
  );
};

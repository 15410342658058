import { FC, ReactNode } from 'react';
import { Form } from 'react-final-form';
import { Button, Drawer, FormElement, FormGroup, Label } from '@smart-kasa/ui';

import { Field, RangePicker } from 'components';
import { formatDate } from 'utils/date';
import { ReceiptState, ReceiptType } from 'services/api/core/receipts/enums';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

export interface IReceiptFilterForm {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values) => void;
}

type ITypeField = {
  value: ReceiptType;
  label?: string;
  icon?: ReactNode;
};

type IStateField = {
  value: ReceiptState;
  label?: string;
  icon?: ReactNode;
};

export const TypeField: FC<ITypeField> = ({ value, ...rest }) => (
  <Field.SwitchArray
    name="types"
    value={String(value)}
    direction="row"
    alignItems="center"
    {...rest}
  />
);
export const StateField: FC<IStateField> = ({ value, ...rest }) => (
  <Field.SwitchArray
    name="states"
    value={String(value)}
    direction="row"
    alignItems="center"
    {...rest}
  />
);

export const ReceiptFilterForm: FC<IReceiptFilterForm> = ({
  initialValues,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();

  const handleSubmit = (fd) => {
    onSubmit(fd);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <Drawer
          onClose={onClose}
          title="Фільтр"
          footer={
            <Button onClick={handleSubmit} width="full" color="primary">
              Застосувати
            </Button>
          }
          {...rest}
        >
          <FormElement label={<Label>Переглянути за датами</Label>}>
            <RangePicker
              from={values.dateStart}
              to={values.dateEnd}
              onChangeDateStart={(date) => form.change('dateStart', formatDate(date))}
              onChangeDateEnd={(date) => form.change('dateEnd', formatDate(date))}
            />
          </FormElement>
          <Field.Input name="query" label="Пошук ФН" placeholder="Введіть текст для пошуку" />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
            isClearable
            value={Number(values.shopId)}
          />
          <TerminalSelectField
            name="terminalId"
            label="Термінал"
            placeholder="Оберіть термінал"
            options={terminals.filter((item) => item.shopId === Number(values.shopId))}
            isClearable
            value={Number(values.terminalId)}
          />
          <FormGroup title="Тип">
            <TypeField value={ReceiptType.sale} label="Продаж" />
            <TypeField value={ReceiptType.refund} label="Повернення" />
            <TypeField value={ReceiptType.serviceInput} label="Службове внесення" />
            <TypeField value={ReceiptType.serviceOutput} label="Службова видача" />
            {/* <StateField value={ReceiptState.archived} label="Відкладений чек" /> */}
          </FormGroup>
        </Drawer>
      )}
    </Form>
  );
};

import { FC, Fragment } from 'react';
import { Button, FormElement, Label } from '@smart-kasa/ui';
import { Form } from 'react-final-form';

import { Field, RangePicker } from 'components';
import { formatDate } from 'utils/date';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

export interface IReportSummaryFilterForm {
  initialValues: any;
  onSubmit: (values) => void;
}

export const ReportSummaryFilterForm: FC<IReportSummaryFilterForm> = ({
  initialValues,
  onSubmit,
}) => {
  const { shops } = useShops();
  const { terminals } = useTerminals();

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <Fragment>
          <FormElement label={<Label>Переглянути за датою</Label>}>
            <RangePicker
              from={values.dateStart}
              to={values.dateEnd}
              onChangeDateStart={(date) => form.change('dateStart', formatDate(date))}
              onChangeDateEnd={(date) => form.change('dateEnd', formatDate(date))}
              width="full"
            />
          </FormElement>

          <Field.Input
            name="query"
            label="Пошук ФН/ФН пРРО"
            placeholder="Введіть текст для пошуку"
          />
          <ShopSelectField
            name="shopId"
            label="Торгова точка"
            placeholder="Оберіть торгову точку"
            options={shops}
            isClearable
            value={Number(values.shopId)}
          />
          <TerminalSelectField
            name="terminalId"
            label="Термінал"
            placeholder="Оберіть термінал"
            options={terminals.filter((item) => item.shopId === Number(values.shopId))}
            isClearable
            value={Number(values.terminalId)}
          />

          <Button onClick={handleSubmit} width="full" color="primary">
            Застосувати
          </Button>
        </Fragment>
      )}
    </Form>
  );
};

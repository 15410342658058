import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import cs from 'classnames';
import { Hint, Strong, UAH } from '@smart-kasa/ui';

import { TransactionPaymentType } from 'services/api/core/transactions/enums';
import type { IPaymentTransaction } from 'services/api/core/transactions/types';

import { PaymentType } from '../PaymentType';
import { TransactionPaymentMethod } from '../TransactionPaymentMethod';
import styles from './Transaction.module.scss';

interface IStatsTransactionsItem {
  transaction: IPaymentTransaction;
}

export const Transaction: FC<IStatsTransactionsItem> = ({ transaction }) => {
  const decrease = [TransactionPaymentType.refund, TransactionPaymentType.reversal].includes(
    transaction.paymentTypeId
  );
  const openReceipt = () =>
    window.open(`https://receipt.smartkasa.ua/${transaction.receiptId}`, '_blank', 'noreferrer');

  return (
    <div
      className={cs(styles.item, {
        [styles.highlighted]: transaction.paymentTypeId === TransactionPaymentType.refund,
      })}
      onClick={openReceipt}
    >
      <div className={styles.info}>
        <PaymentType size="small" status={transaction.paymentTypeId} />

        <div className={styles.details}>
          <div className={styles.number}>{transaction.rrn}</div>
          <TransactionPaymentMethod type={transaction.transactionTypeId} />
        </div>
      </div>

      <div className={styles.payment}>
        <Strong>
          <UAH colored value={Number(transaction.amount) * (decrease ? -1 : 1)} />
        </Strong>

        <Hint margin={0}>
          <FormattedDate value={transaction.timestamp} dateStyle="short" timeStyle="short" />
        </Hint>
      </div>
    </div>
  );
};

import cs from 'classnames';
import { Row, Col } from '@smart-kasa/ui';

import styles from './Report.module.scss';

export type ReportRowAppearance = 'none' | 'underline';

type ReportRowProps = {
  title: any;
  value?: any;
  appearance?: ReportRowAppearance;
  className?: string;
};

export const ReportRow = ({
  title,
  value,
  appearance = 'underline',
  className,
}: ReportRowProps) => (
  <Row className={cs(styles.row, styles[appearance], className)}>
    <Col span={16} className={cs(styles.column, styles.rowTitle)}>
      {title}
    </Col>
    <Col span={8} className={cs(styles.column, styles.rowValue)}>
      {value}
    </Col>
  </Row>
);
